//import { DisplayLabel } from './components/DisplayLabel';

//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// import 'dark-mode-switch';
//import '/css/test.scss';
var double_check = true;
import './passwordstrenght.js';
import './passwordcheck.js';




//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

// import './dark-mode-switch.js';

//https://swiperjs.com/get-started
//import Swiper from 'swiper';
  // import Swiper styles
  //import 'swiper/css';

  //const swiper = new Swiper(...);

//import Masonry from 'masonry-layout';

//import barba from '@barba/core';

//import gsap from "gsap";

//npm install flickity-imagesloadedimport PhotoSwipeLightbox from 'photoswipe/lightbox';

//https://www.lightgalleryjs.com/
// import lightGallery from 'lightgallery';

// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"


//var msnry = new Masonry( '.grid', {});

// let Main = {
//   init: async function () {

//     // initialize demo javascript component - async/await invokes some 
//     //  level of babel transformation
//     const displayLabel = new DisplayLabel();
//     await displayLabel.init();

//   }
// };

// barba.init({
//   transitions: [{
//     name: 'default-transition',
//     leave() {
//       // create your stunning leave animation here
////       // console.log('Leave');
//       // document.body.classList.add("fade-out");
//     },
//     enter() {
//       // create your amazing enter animation here
////       // console.log('Enter');
//       // document.body.classList.add("fade-out-enter");
//       // st = setTimeout(function(){
//       //   document.body.classList.remove("fade-out-enter","fade-out");
//       // },1300);
      
//     }
//   }]
// });




/* audio */
// var playBtn = document.getElementById('P'),
//   playBtn1 = document.getElementById('A'),
//   playBtn2 = document.getElementById('A2'),
// audios = document.querySelectorAll('audio');
//// console.log(audios);

// if (document.getElementById("P")) {

//   playBtn.addEventListener('click', function () {
//     [].forEach.call(audios, function (audio) {
//       // do whatever
//       audio.play();
//     });
//   }, false);
//   playBtn1.addEventListener('click', function () {
//     [].forEach.call(audios, function (audio) {
//       // do whatever
//       audio.play();
//     });
//   }, false);
//   playBtn2.addEventListener('click', function () {
//     [].forEach.call(audios, function (audio) {
//       // do whatever
//       audio.play();
//     });
//   }, false);
// }
/* audio */





// playBtn.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);
// playBtn1.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);

// playBtn2.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);

// resetBtn.addEventListener('mouseover', function () {
//   heartbeat.play();
// }, false);

// resetBtn.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);



// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
//   alert('boe');
// })

// barba.init({
//   timeout: 10000,
//   requestError: (trigger, action, url, response) => {
//     // go to a custom 404 page if the user click on a link that return a 404 response status
//     if (action === 'click' && response.status && response.status === 404) {
//       barba.go('/404');
//     }

//     // prevent Barba from redirecting the user to the requested URL
//     // this is equivalent to e.preventDefault() in this context
//     return false;
//   },
//   transitions: [{
    
//     sync: true,
//     name: 'opacity-transition-yolo',
//     leave(data) {
//       return gsap.to(data.current.container, {x: 0, y:0, duration: .25, opacity:0});
//     },
//     enter(data) {
//       return gsap.from(data.next.container, {x: 0, y:0, duration: .4, opacity:0.4});
//     }
//   }]
// });


// barba.hooks.afterEnter((data) => {
////   //console.log(data.next.namespace);
////   //alert('console.log(data.next.namespace);');
//   var Pnav = document.getElementsByClassName('single-post-nav-wr')[0];
//   var PnavHtml = Pnav.innerHTML;
//   var Cnav = document.getElementsByClassName('outer-barba')[0];
//   Cnav.innerHTML = '';
//   Pnav.innerHTML = '';
//   Pnav.remove();
////   // console.log(Pnav);
////   // console.log('!');
//   Cnav.innerHTML = PnavHtml;

 

// });

// var Flickity = require('flickity');
// require('flickity-imagesloaded');
// require('flickity-fullscreen');

//Main.init();



const divs = document.querySelectorAll('.show-more');
const divs_l = document.querySelectorAll('.show-less');

divs.forEach(el => el.addEventListener('click', event => {
  ////console.log(event.target.getAttribute("class"));
  var n = event.target.nextSibling;
  n.classList.remove('d-none');
  n.nextSibling.classList.remove('d-none');
  event.target.classList.add('d-none');
  
  //this.classList.add('good');
}));
divs_l.forEach(el => el.addEventListener('click', event => {
  ////console.log(event.target.getAttribute("class"));
  event.target.previousSibling.classList.add('d-none');
  event.target.previousSibling.previousSibling.classList.remove('d-none');
  event.target.classList.add('d-none');
  //this.classList.add('good');
}));



/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
function getCookie(name) {
  let value = `; ${document.cookie}`;
  let parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

//document.cookie = 'snack=1; path=/; max-age=${60 * 60 * 24 * 14};';
//console.log(document.cookie);


function deleteCookie(cookieName) {
  document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function hide_news_based_on_cookies() {
  //console.log('//hide_news_based_on_cookies');
  let cks = getCookie('snack');
  //console.log(typeof cks);
  //console.log(cks);
  if (cks !== undefined && typeof cks ==='string') {
    //console.log('🍪🍪🍪🍪🍪🍪 yum yum 🍪🍪🍪🍪🍪');
    let post_id_array = cks.split(",");
    post_id_array = post_id_array.filter(Number);
    let uniqueChars = post_id_array.filter((element, index) => {
      return post_id_array.indexOf(element) === index;
    });
    let css = '';
    let count = post_id_array.length;
    //console.log('count:' + count);
    let i = 1;
    post_id_array.forEach(element => {
      //console.log('-----');
      //console.log(element);
      css += '.post-alert-' + element
      if (i < count) {
        css += ',';
      }
      i++;
    });
    css = css + '{display:none !important;}'
    //console.log(uniqueChars);
    document.head.innerHTML += '<style>'+css +'</style>';
  } else {
    //console.log('//no 🍪 found');
  }
  //deleteCookie('snack');
}


document.addEventListener("DOMContentLoaded", function() {
  hide_news_based_on_cookies();

  const myAlert = document.getElementById('latestAlert');
  //// console.log('typeof myAlert');
  //// console.log(typeof myAlert);
  if (typeof (myAlert) != 'undefined' && myAlert != null) {
   
    let c = getCookie('snack');
    //if (c == undefined) {
    ////console.log(typeof c);
    ////console.log(c);
    //}
    myAlert.addEventListener('closed.bs.alert', event => {
      let post_id = myAlert.getAttribute('data-post-id');
      let current_cookie;
      if (typeof post_id !== 'undefined') {
        if (c == undefined) {
          ////console.log('it is undefined');
          current_cookie = '';
        }else{
          current_cookie = c;
          ////console.log('it is NOT undefined');
        }
        let new_cookie = current_cookie + post_id + ',';
        ////console.log(new_cookie);
        document.cookie = 'snack =' + new_cookie + '; path=/; max-age=${60 * 60 * 24 * 14};';
      } else {
        ////console.log('delete');
         
      }
      
    })
  }

  var to = setTimeout(function () {
    document.querySelector("body").classList.remove('begin-face');
  }, 100);
  var to2 = setTimeout(function () {
    document.querySelector("body").classList.remove('end-face');
  }, 700);
  //document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  // Enabling all the tooltips on the page.
  const tooltipElements =
    document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltips =
    [...tooltipElements].map(el => new bootstrap.Tooltip(el));

  
  // var windowWidth = window.innerWidth;
  //// console.log(windowWidth);
  // if(windowWidth <= 767){
  
  //   const cbox = document.querySelectorAll(".menu-item");

  //   for (let i = 0; i < cbox.length; i++) {
  //     cbox[i].addEventListener("mousedown", function(){
  //       bsOffcanvas.toggle();
  //     });
  //   }

  // }

  // var menuitem = document.querySelectorAll('.menu-item');
  // menuitem.addEventListener("mousedown", function(){
  //   alert('!');
  // });
  
 //const highlightedItems = document.querySelectorAll(".portfolio-item");

  
 
  // var st = setTimeout(function(){
  //   highlightedItems.forEach((userItem) => {
  //     userItem.classList.remove("hide-car");
  //   });
  // }, 500);
  
  const textPath = document.querySelector("#text-path");
  if (typeof (textPath) != 'undefined' && textPath != null) {
    const h = document.documentElement,
      b = document.body,
      stt = 'scrollTop',
      sh = 'scrollHeight',
      offset = 0;

    document.addEventListener("scroll", e => {
      let percent = (h[stt] || b[stt]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
      textPath.setAttribute("startOffset", (-percent * 40) + offset)
    });
  }





});

//console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
}else{
  //console.log('Looks like we are in production mode');
}
 







